@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'PoppinsBold';
  src: url('./Components/utils/Fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('./Components/utils/Fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./Components/utils/Fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'PoppinsRegular';
  src: url('./Components/utils/Fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Cartis';
  src: url('./Components/utils/Fonts/Cartis.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace,'Times New Roman'
}

@layer components {
  .F-JC-AI-CENTER {
    @apply flex items-center justify-center;
  }
  .NormalTextClass{
    @apply text-NormalText font-PoppinsRegular text-black
  }
  .LargeClass{
    @apply text-ExtraLarge font-Cartis text-black max_xl:text-MMExtraLarge max_md:text-MExtraLarge 
    max_768:text-[48px] max_720:text-[34px] max_sm:text-[28px] max_sm_400:text-[23px]  max_esm_320:text-[20px]                                  
  }
  .NormalText16{
    @apply text-CommonText text-black font-PoppinsRegular max_md:text-TCommonText
  }
  .NormalText18{
    @apply text-TNormalText text-black font-PoppinsRegular max_md:text-MNormalText
  }
  .NormalText20{
    @apply text-NormalText text-black font-PoppinsMedium max_md:text-TNormalText
  }
  .commonHeading {
    @apply text-Heading font-bold max_md:text-THeading;
    font-family: 'Times New Roman', Times;
  }
  .commonHeadingPoppin {
    @apply text-[22px] font-PoppinsRegular max_md:text-[18px];
  }
  .Heading24{
    @apply text-THeading text-black font-bold max_md:text-NormalText;
    font-family: 'Times New Roman';
  }
  .inputField{
    @apply w-[100%] bg-PrimaryOrange py-2 px-4 border-none outline-none rounded-ButtonRadius
    text-CommonText text-black font-PoppinsRegular max_md:text-TCommonText
  }
  .text42{
    @apply text-[42px] max_md:text-[34px] text-PrimaryBrown max_sm:text-[22px] font-bold;
    font-family: 'Times New Roman';
  }
}
.slick-track{
  padding-top: 40px !important;
}
@media(max-width:720px){
  .slick-list{
    width:86%;
  }
}
@media(max-width:620px){
  .slick-list{
    width:100%;
  }
}
@media(max-width:500px){
  .slick-list{
    width:65%;
  }
}
/* @media(max-width:620px){
  .slick-slide{
    margin-right: 20px;
  }
} */
.abc {
  position: relative;
}
.abc:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #A75F4A;
  height: 4px;
}
.abc:hover:after {
  width: 100%;
}
.abc,
.abc:after,
.abc:before {
  transition: all .5s;
}

/* .loader {
  width: 22rem;
  height: 22rem;
  border-radius: 70%;
  border: 1rem solid white;
  border-top: 1rem solid #A75F4A;  
  animation: spin 6s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader2 {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 1rem solid white;
  border-top: 1rem solid #D3A990;  
  animation: spin1 4s linear infinite reverse;
}

@keyframes spin1 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader3 {
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  border: 1rem solid white;
  border-top: 1rem solid #FFF2D6;  
  animation: spin2 12s linear infinite;
}
@keyframes spin2 {
  100% { transform: rotate(0deg); }
  0% { transform: rotate(360deg); }
} */
.custom-loader {
  width: 24rem;
  height: 24rem;
  display: grid;
  animation: s4 12s infinite;
}
.custom-loader::before,
.custom-loader::after {    
  content:"";
  grid-area: 1/1;
  border:1rem solid;
  border-radius: 50%;
  border-color:#A75F4A #A75F4A #0000 #0000;
  mix-blend-mode: darken;
  animation: s4 2s infinite linear;
}
.custom-loader::after {
  border-color:#0000 #0000 #FFFFFF #FFFFFF;
  animation-direction: reverse;
}

@keyframes s4{ 
  100%{transform: rotate(1turn)}
}
.loader {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 1rem solid;
  border-color: #A75F4A #A75F4A transparent transparent;
  box-sizing: border-box;
  animation: rotation 6s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1rem solid;
  border-color: transparent transparent #D3A990 #D3A990;
  width: 28rem;
  height: 28rem;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 26rem;
  height: 26rem;
  border-color: #FFF2D6 #FFF2D6 transparent transparent;
  animation: rotation 3s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@media(max-width:768px){
  .loader {
    width: 25rem;
    height: 25rem;
  }
  .loader::after,
.loader::before {
  width: 23rem;
  height: 23rem;
  
}
.loader::before {
  width: 21rem;
  height: 21rem;
  
}
}
@media(max-width:430px){
  .loader {
    width: 13rem;
    height: 13rem;
    border: 0.6rem solid;
  border-color: #A75F4A #A75F4A transparent transparent;
  }
  .loader::after,
.loader::before {
  width: 12rem;
  height: 12rem;
  border: 0.6rem solid;
  border-color: #D3A990 #D3A990 transparent transparent;
  
}
.loader::before {
  width: 11rem;
  height: 11rem;
  border-color: #FFF2D6 #FFF2D6 transparent transparent;
  
}
}

.arrows {
	width: 50px;
	height: 60px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 20px;
}

.arrows path {
	stroke: #2994D1;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}

.scroll-down {
  position: absolute;
  bottom: 100px;
  left: 53%;
  width: 120px;
  height: 120px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  /* bottom: 0; */
  transform: translateX(-50%);
}
.scroll-down.arrows span {
  position: absolute;
  display: inline-block;
  top: 20px;
  height: 25px;
  width: 2px;
  left: calc(50% - 1px);
  animation: animateArrows 1.25s infinite linear;
}
@keyframes animateArrows {
  0%, 40%, 100% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
.scroll-down.arrows span:first-child {
  top: 35px;
  animation-delay: 0s;
}
.scroll-down.arrows span:nth-child(2) {
  top: 35px;
  animation-delay: .33s;
}
.scroll-down.arrows span:last-child {
  top:  50px;
  animation-delay: .66s;
}
.scroll-down.arrows span::before,
.scroll-down.arrows span::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  top: 0;
  left: 0;
  background: #A75F4A;
  border-radius: 3px;
}
.scroll-down.arrows span::before {
  transform-origin: bottom;
  transform: rotate(-45deg);
}
.scroll-down.arrows span::after {
  transform-origin: bottom;
  margin-left: -1px;
  transform: rotate(45deg);
}
@media(max-width:768px){
  .scroll-down{
    left:54%;
    bottom: 250px;
  }
}
@media(max-width:650px){
  .scroll-down{
    left:55%;
  }
}
@media(max-width:540px){
  .scroll-down{
    left:56%;
  }
}
@media(max-width:500px){
  .scroll-down{
    left:57%;
    bottom: 0px;
  }
}
@media(max-width:375px){
  .scroll-down{
    left:59%;
    bottom: 0px;
  }
}
@media(max-width:350px){
  .scroll-down{
    left:60%;
  }
}
@media(max-width:300px){
  .scroll-down{
    left:61%;
  }
}

.serviceSlick .slick-track{
  padding-top: 0px !important;
}
.serviceSlick .slick-list{
  width: 92% !important;
}

